// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./ftnotification.scss"></require>
  <section id="ftnotifications" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="profile.notifications.notifications"></h1>
    </div>
    <div class="flex-grow"></div>
    <div class="notification_content">
      <!-- <p class="firsttext" i18n="profile.notifications.notifications_message"></p> -->
      <ul>
        <li>
          <!-- <img src="" alt=""> -->
          <span t="profile.notifications.notifications_payment"></span>
        </li>
        <li>
          <!-- <img src="" alt=""> -->
          <span t="profile.notifications.notifications_reminder"></span>
        </li>
        <li>
          <!-- <img src="" alt=""> -->
          <span t="profile.notifications.notifications_movement"></span>
        </li>
      </ul>
      <div class="spacer-32"></div>
      <div class="stat-check flex-row">
        <label class="status" for="notifications"
          t.bind="areftnotificationson ? 'profile.notifications.notifications_enabled':'profile.notifications.notifications_disabled'"></label>
        <label class="switch">
          <input type="checkbox" checked.bind="areftnotificationson" id="notifications">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary"   if.bind="state.me.isFirstTime == true"  click.delegate="goNextft()" i18n="home.next"></button>
    <button class="btn btn-secondary" if.bind="state.me.isFirstTime == true"  click.delegate="goNextft()" i18n="home.later"></button>
  </section>
</template>
`;
// Exports
export default code;