// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./ftwelcome.scss"></require>
  <section id="ftwelcome" class="flex-column">
    <div class="spacer-default"></div>
    <img src="${___HTML_LOADER_IMPORT_0___}">
    <h1 i18n="banka"></h1>
    <p class="slogan" i18n="slogan"></p>
    <div class="flex-grow"></div>
    <!-- <h2>\${me.displayName}</h2><br> -->
    <h3 i18n="welcome!"></h3>
    <p class="subtitle"> \${me.displayName} <span t="welcome_subtitle2"></span></p>
    <button class="btn btn-primary" click.delegate="goToLanguage()">
      <span i18n="ftwelcome_launch"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>

`;
// Exports
export default code;