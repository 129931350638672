// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/homedark.png", import.meta.url);
// Module
var code = `<template>
  <require from="./fttheme.scss"></require>
  <section id="fttheme" class="flex-column">
    <div class="header">
      <h1 i18n="fttheme1">Choisir un thème</h1>
    </div>
    <div class="flex-grow"></div>
    <div class="chooseTheme flex-row">
      <div class="theme" class.bind="isDarkTheme ? 'has_selected': ''" click.delegate="isDarkTheme = !isDarkTheme">
        <img src="${___HTML_LOADER_IMPORT_0___}">
        <span i18n="fttheme2"></span>
      </div>
      <div class="theme flex-column" class.bind="isDarkTheme ? '': 'has_selected'"
        click.delegate="isDarkTheme = !isDarkTheme">
        <img src="${___HTML_LOADER_IMPORT_0___}">
        <span i18n="fttheme3"></span>
      </div>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" t="choose" click.delegate="goback()"></button>
    <div class="spacer-32"></div>
  </section>
</template>
`;
// Exports
export default code;