// Module
var code = `<template>
  <require from="./forgot.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <require from="../../../components/header-standard/header-standard"></require>

  <section id="forgot" class="flex-column">
    <header-standard show-back-button.bind="showBackButton" show-message.bind="showMessage" message.bind="message" show-logo.bind="showLogo" show-language.bind="showLanguage"></header-standard>    
    <form class="inputs flex-column" id="mailForm">
      <div>
        <input id="user_mail" type="user_mail" value.bind="user_mail" i18n="[placeholder]auth.email_adresse"
          class.bind="displayError ? 'shake' : ''" autofocus="autofocus" />
        <i></i>
      </div>
      <span id="preform" i18n="auth.forgot.forgot2"></span>
    </form>
    <div class="flex-grow"></div>
    <div class='error' class.bind="displayError ? 'shake' : ''" if.bind='displayError' style="background: none; flex:1">
      <p>
        \${errorMessage}
      </p>
    </div>
    <div class="flex-grow"></div>
    <button type="submit" class="btn btn-primary" disabled.bind="!user_mail" form="mailForm"
      click.delegate="sendEmail()" disable.bind="IsLoading">
      <span if.bind="!isLoading" i18n="home.send"></span>
       <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
    <div class="secondary flex-column">
      <p id="notmember" i18n="auth.forgot.forgot8"></p>
      <a click.delegate='toRegister()'>
        <span id="connect" i18n="auth.forgot.forgot5"></span>
      </a>
    </div>
  </section>
</template>
`;
// Exports
export default code;