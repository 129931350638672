import { observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AuthService } from "aurelia-auth";
import { SingletonService } from "singleton";
import { autoinject } from "aurelia-dependency-injection";
import { User } from "components/models/UserModel";
import { Router } from "aurelia-router";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { json } from "aurelia-fetch-client";
import { checkResponseStatus } from "http_clients/checkResponseStatus";

@autoinject
export class FtCreatePaymail {
  private me: User;
  private paymail: string;
  private name: string;
  private mybanka: string = "@mybanka.eu";
  private displayError: boolean = false;
  private errorMessage;
  private isLoading: boolean = false;

  constructor(
    private i18n: I18N,
    private router: Router,
    private singleton: SingletonService,
    private authService: AuthService,
    private userHttpClients: UsersHttpClients
  ) {}

  attached() {
    this.getMe();
  }
  /* ici on fetch le client pour afficher ces information */
  async getMe() {
    this.me = await this.authService.getMe();
    console.log(this.me);
  }

  async setPaymail() {
    this.isLoading = true;
    this.name = this.sanitizeName(this.name);
    this.paymail = this.name + this.mybanka;
    this.me.paymail = this.paymail;
    try {
      let httpRequest = await this.userHttpClients.fetch("/profile", {
        method: "PATCH",
        body: JSON.stringify({
          paymail: this.paymail,
        }),
      });
      let is200Ok = await checkResponseStatus(httpRequest);
      let response = await is200Ok.json();
      this.singleton.setMe(response);
      this.router.navigateToRoute("ftnotification");
    } catch (error) {
      this.isLoading = false;
      this.displayError = true;
      console.log(error);
      if (error.msg.includes("duplicate key error")) {
        this.errorMessage = this.i18n.tr("home.error..error_already_exist");
      } else {
        this.errorMessage =  this.i18n.tr("home.error.error_global")
      }
    }
  }

  sanitizeName(name: string): string {
    const sanitizedName = name.toLowerCase().replace(/[^a-z0-9_]/g, "_");
    return sanitizedName;
  }
}
