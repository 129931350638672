// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/phone.png", import.meta.url);
// Module
var code = `<template>
  <require from="./ftphone.scss"></require>
  <require from="../../../../components/phone_country/phone_country"></require>
  <section id="ftphone" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="auth.firsttime.phone_title"></h1>
    </div>
    <div class="spacer-32"></div>
    <img src="${___HTML_LOADER_IMPORT_1___}" alt="">
    <div class="spacer-default"></div>
    <form class="inputs" id="phone" class.bind="shaking ? 'shake' : ''">
      <div>
        <phone-country></phone-country>
        <input type="number" value.bind="me.phone" i18n="[placeholder]auth.firsttime.phone_title" required/>
      </div>
    </form>  
    <p class="phone_message" t="auth.firsttime.phone_message"></p>
    <div class="flex-grow"></div>
    <div class="error flex-column" class.bind="displayError ? 'shake' : ''" if.bind="displayError && !success">
      <span> \${errorMessage} </span>
    </div>
    <button class="btn btn-primary" form="phone" t="auth.validate" if.bind="me.phone || !me.phone"
      click.delegate="me.phone ? validate() : shakeError()" disabled.bind="!me.phone">
    </button>
    <button class="btn btn-secondary" href="/choose_type_account" t="home.later" click.delegate="later()"></button>
  </section>
</template>
`;
// Exports
export default code;