import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { AuthService } from "aurelia-auth";
import { SingletonService } from "singleton";
import { json } from "aurelia-fetch-client";
import { User } from "components/models/UserModel";
import { I18N } from "aurelia-i18n";

@autoinject
export class ftphone {
  private me: User;
  private shaking: boolean = false;
  private phone;
  private errorMessage;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userHttpClients: UsersHttpClients,
    private singleton: SingletonService,
    private i18n: I18N,
  ) {}

  attached() {
    this.getMe();
  }
  /* ici on fetch le client pour afficher ces information */
  async getMe() {
    this.me = await this.authService.getMe();
  }

  async validate() {
    try {
      let httpRequest = await this.userHttpClients.fetch("/profile", {
        method: "PATCH",
        body: json(this.me),
      });
      let is200Ok = await checkResponseStatus(httpRequest);
      let response = await is200Ok.json();
      this.singleton.setMe(response);
    } catch (error) {
      this.handleErrors(error);
    }
    this.router.navigateToRoute("pin_code_create");
  }

  later() {
    this.router.navigateToRoute("pin_code_create");
  }

  handleErrors({ errors }) {
   {
      this.errorMessage = this.i18n.tr("home.error..error_already_exist");
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  goBack() {
    this.router.navigateBack();
  }
}