import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { AuthService } from 'aurelia-auth';
import { Router } from 'aurelia-router';

@autoinject
export class ForgotConfirmed {
  constructor(private router: Router) {
  }

  goToLogin() {
    let that = this
    that.router.navigateToRoute("login");
  }
}
