import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { AuthService } from 'aurelia-auth';
import { User } from 'components/models/UserModel';
import { SingletonService } from 'singleton';

@autoinject
export class Ftwelcome {
  private me: User;

  constructor(
    private authService: AuthService,
    private singleton: SingletonService,
    private router: Router) {
    this.getMe();

  }
  async getMe() {
    let that = this
    this.me = await this.authService.getMe();
  }
  goToLanguage() {
    // this.me.isFirstTime = true;
    // this.router.navigateToRoute('choose_language')
    this.router.navigateToRoute('ftprofilepic')
  }
}
