// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./confirmed.scss"></require>
  <section id="forgot_confirmed" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="auth.forgot.forgot_confirmed1"></h1>
    </div>
    <div class="flex-grow"></div>
    <i class="far fa-envelope"></i>
    <p>
      <span i18n="auth.forgot.forgot_confirmed2"></span><br>
      <span i18n="auth.forgot.forgot_confirmed3"></span>
    </p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToLogin()">
      <span i18n="home.goBackHome"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;