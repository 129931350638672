// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./ftintro.scss"></require>
  <section id="ftintro" class="flex-column">
    <div class="rond_droite"></div>
    <div class="rond_gauche"></div>
    <img src="${___HTML_LOADER_IMPORT_0___}">
    <h1>MyBanka</h1>
    <h2>Your money. Your way</h2>
    <div class="flex-grow"></div>
    <h3>
      <span i18n="welcome"></span> \${me.displayName} !
    </h3>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" t="ftintro_launch" click.delegate="goToLanguage()"></button>
    <div class="spacer-32"></div>
  </section>
</template>
`;
// Exports
export default code;