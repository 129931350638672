// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/camera.png", import.meta.url);
// Module
var code = `<template>
  <require from="./ftprofilepic.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="ftprofilepic" class="flex-column">
    <div id="header" class="flex-row">
      <h1 i18n="auth.firsttime.ftprofilepic1"></h1>
    </div>
    <div class="image flex-column" class.bind="shaking ? 'shake' : ''">
      <img ref="previewPic" src="${___HTML_LOADER_IMPORT_0___}" click.delegate="changeProfilePic()">
      <a i18n="auth.firsttime.ftprofilepic2" click.delegate="changeProfilePic()"></a>
      <input value.bind="me.urlPic" ref="myInput" type="file" files.bind="selectedFile" accept="image/*"
        change.delegate="onSelectFile()" style="display: none;" />
    </div>
    <div class="flex-grow"></div>
    <div class='error flex-column' if.bind='displayError'>
      <span>
        \${errorMessage}
      </span>
    </div>
    <div class="flex-grow" if.bind='displayError'></div>
    <button class="btn btn-primary fakeDisabled" 
    click.delegate="shakeError()" t="home.next" if.bind="!state.me.urlPic && !isLoading"></button>
    <button class="btn btn-primary" 
      click.delegate="goNextHomeEndOfFt()" 
      t="home.next"
      if.bind="state.me.urlPic && !isLoading"></button>
    <button class="btn btn-primary" disabled if.bind="isLoading">
      <loader-ring class="loader-btn"></loader-ring>
    </button>
    <button class="btn btn-secondary" t="home.later" 
      click.delegate="router.navigateToRoute('pin_code_create')">
  </button>
  </section>
</template>
`;
// Exports
export default code;