import { Router } from 'aurelia-router';
import { UsersHttpClients } from "../../../http_clients/UsersHttpClients";
import { AuthService } from 'aurelia-auth';
import { autoinject, bindable } from 'aurelia-framework';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { json } from 'aurelia-fetch-client';
import { I18N } from "aurelia-i18n";

@autoinject

export class Forgot {
  private user_mail: string;
  private errorMessage: string;
  private displayError: boolean;
  private isLoading: boolean;

  showBackButton = true;
  showMessage = true;
  message = this.i18n.tr("auth.forgot.forgot1");
  showLogo = false;
  showLanguage = false;

  constructor(private authService: AuthService,
    private usersHttpClients: UsersHttpClients,
    private router: Router,
    private i18n: I18N,
    private userHttpClients: UsersHttpClients) { }

  /* Call to user API to ask a change_password mail  */
  async sendEmail() {
    this.errorMessage = "";
    this.displayError = false;
    this.isLoading = true;
    try {
      let httpRequest = await this
        .userHttpClients
        .fetch('/auth/forgot', {
          method: 'POST',
          body: json({ "email": this.user_mail, })
        })
      let is200Ok = await checkResponseStatus(httpRequest);
      let response = await is200Ok.json();
      this.router.navigateToRoute('forgot_confirmed')
    } catch (error) {
      this.handleErrors(error)
    }
  }
  handleErrors(error) {
    if (error.param == "email") {
      if (error.msg == "EMAIL_IS_NOT_VALID") {
        this.errorMessage = this.i18n.tr("auth.forgot.forgot_error_EmailIsNotValid");
      }
    }
    if (error.msg != "EMAIL_IS_NOT_VALID") {
      this.errorMessage = this.i18n.tr("auth.forgot.forgot_error_UserDoesNotExist");
    }
    this.displayError = true;
    this.isLoading = false;
  }
  toRegister() {
    this.router.navigateToRoute('register')
  }
  goBack() {
    this.router.navigateBack()
  }
}
