// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/@.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./ft_create_paymail.scss"></require>
  <section id="ft_create_paymail" class="flex-column">
    <h4 t="ft_create_paymail.title">Création de votre Paymail</h4>
    <div class="spacer-default"></div>
    <div class="icon_informative_container flex-column">
      <div class="icon flex-column">
        <img src="${___HTML_LOADER_IMPORT_0___}">
      </div>
      <div class="informative flex-row">
        <span t="ft_create_paymail.informative_start">Ce</span><abbr title="\${'ft_create_paymail.informative_hover' | t}">paymail</abbr><span t="ft_create_paymail.informative_end">pourra être utilisé pour recevoir des paiements (BSV).</span>
      </div>
    </div>
    <div class="flex-grow"></div>
    <form class="inputs flex-row" id="paymailform" submit.delegate="setPaymail()">
      <input class="left" id="name" type="string" value.bind="name" placeholder="Alice" required autofocus>
      <input class="right" id="mybanka" type="string" value="@mybanka.eu" readonly>
    </form>
    <div class="flex-grow" if.bind="displayError"></div>
    <div class="error" class.bind="displayError ? 'shake' : ''" if.bind="displayError">
      <span> \${errorMessage} </span>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" type="submit" form="paymailform" disabled.bind="isLoading || !name">
      <span i18n="validate" if.bind="!isLoading"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
    <button class="btn btn-secondary" click.delegate="router.navigateToRoute('ftnotification')" if.bind="me.isFirstTime">
      <span i18n="later" ></span>
    </button>
  </section>
</template>
`;
// Exports
export default code;