// Module
var code = `<template>
  <require from="./ftfriends.scss"></require>
  <section id="ftfriends" class="flex-column">
    <div class="header">
      <h1 i18n="choose-your-friends"></h1>
    </div>
    <div class="flex-grow"></div>
    <div class="savings" click.delegate="goToConsole()">
      <i class="fas fa-plus"></i>
    </div>
    <h3 i18n="access-list-contacts"></h3>
    <div class="description">
      <input i18n="[placeholder]texte_description" />
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" i18n="choose-your-friends2">Suivant</button>
  </section>
</template>
`;
// Exports
export default code;