import { SingletonService } from '../../../../singleton';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { FileUploadHttpClients } from 'http_clients/FileUploadHttpClients';
import { AuthService } from 'aurelia-auth';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { UsersHttpClients } from 'http_clients/UsersHttpClients';
import { User } from 'components/models/UserModel';
// import { json } from 'aurelia-fetch-client';
import { I18N } from "aurelia-i18n";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { EventAggregator } from "aurelia-event-aggregator";

@connectTo()
@autoinject
export class Ftprofilepic {
  private name: string;
  private me: User = new User;
  private goal_amount: number;
  private urlPic: string;
  private selectedFile;
  private shaking: boolean = false;
  private myInput;
  private formData: FormData;
  private IsImageChosen: boolean = false;
  private userInformationToUpdate: User;
  private isLoading: boolean = false;
  private displayError: boolean = false;
  private state: State;

  previewPic: HTMLImageElement;

  constructor(private userHttpClient: UsersHttpClients,
    private fileUploadHttpClient: FileUploadHttpClients,
    private authService: AuthService,
    private router: Router,
    private singleton: SingletonService,
    private i18n: I18N,
    private ea: EventAggregator,
    private store: Store<State>) { }


  changeProfilePic() {
    this.myInput.click();
  }


  onSelectFile() {
    this.formData = new FormData()
    this.formData.append('upload', this.selectedFile[0]);
    const file = this.selectedFile[0];

    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.previewPic.src = reader.result.toString();
      });
      reader.readAsDataURL(file);
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  async uploadObjectiveImage() {
    // await httpRespose
    let httpResult;

    try {
      httpResult = await this.fileUploadHttpClient.fetch('/fileupload/', {
        method: 'POST',
        body: this.formData
      });
      let isHttpOk = await checkResponseStatus(httpResult)
      let json_response = await isHttpOk.json();
      return json_response.url; // URL IS INSIDE THE JSON
    } catch(error) {
      if (httpResult.status == 422) {
        this.isLoading = false;
        this.displayError = true;
      }
    }
  }

  async goNextHomeEndOfFt() {
    this.isLoading = true;
    try{
      console.log("before " + this.state.me.urlPic)
      this.state.me.urlPic = await this.uploadObjectiveImage();
      console.log("after " + this.state.me.urlPic)
      this.ea.publish('profile.update', this.state.me);
      this.router.navigateToRoute('pin_code_create');
    } catch(error) {
    }
  }
}

