import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { I18N } from 'aurelia-i18n';

@autoinject
export class FtFriends {


    constructor(private i18n: I18N,
        private router: Router) { }
}