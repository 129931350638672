import { observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
// import { AuthService } from 'aurelia-auth';
import { SingletonService } from "singleton";
import { autoinject } from "aurelia-dependency-injection";
import { User } from "components/models/UserModel";
import { Router } from "aurelia-router";
import { connectTo } from "aurelia-store";

@connectTo()
@autoinject
export class FtNotifications {
  @observable private areftnotificationson: boolean =
    this.parseStringToBoolean(localStorage.getItem("notificationEnabled")) ||
    false;

  constructor(
    private i18n: I18N,
    private router: Router,
    private singleton: SingletonService
  ) {}

  attached() {
    try {
      if (
        Notification.permission === "granted" &&
        localStorage.getItem("notificationEnabled") === "true"
      ) {
        this.areftnotificationson = true;
      }
    } catch (error) {
      console.error("Error in FtNotifications attached():", error);
    }
  }

  parseStringToBoolean(str: string): boolean {
    try {
      if(!str)
        return false;
      const normalizedString = str.trim().toLowerCase();
      return normalizedString === "true" || normalizedString === "1";
    } catch (error) {
      console.error("Error parsing string to boolean:", error);
      return false;
    }
  }

  async areftnotificationsonChanged(newVal) {
    console.log("current notif status " + this.areftnotificationson);
    // this.areftnotificationsOn = !this.areftnotificationsOn;
    // if(!newVal)
    //   return;
    if (newVal === false) {
      this.setNotificationEnabled(false);
      return;
    } else {
      this.setNotificationEnabled(true);
    }

    const notificationEnabled =
      localStorage.getItem("notificationEnabled") === "true";

    // Let's check if the browser supports ftnotifications
    if (!("Notification" in window)) {
      alert(this.i18n.tr("home.alerts.desktop_no_notif"));
      this.setNotificationEnabled(false);
      return;
    }

    // Let's check whether ftnotification permissions have already been granted
    if (Notification.permission === "granted" && notificationEnabled) {
      // If it's okay let's create a ftnotification
      const notification = new Notification(this.i18n.tr("notifications"), {
        body: this.i18n.tr("auth.firsttime.ftnotifications_active"),
        icon: "https://mybanka.eu/img/banka_logo.png",
      });
      // console.log(notification)
      // var ftnotification = new ftnotification();
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      let permission = await Notification.requestPermission();
      // If the user accepts, let's create a ftnotification
      if (permission === "granted") {
        const notification = new Notification(this.i18n.tr('profile.notifications.notifications'), {
          body: this.i18n.tr('auth.firsttime.ftnotifications_active'),
          icon: "https://mybanka.eu/img/banka_logo.png"
        } );
        console.log(notification)
        this.areftnotificationson = true;
      } else {
        this.setNotificationEnabled(false);
        this.areftnotificationson = false;
      }
    } else if (Notification.permission === "denied") {
      let permission = await Notification.requestPermission();
      // If the user accepts, let's create a ftnotification
      if (permission === "granted") {
        const notification = new Notification(this.i18n.tr("profile.notifications.notifications"), {
          body: this.i18n.tr("auth.firsttime.ftnotifications_active"),
          icon: "https://mybanka.eu/img/banka_logo.png",
        });
        // console.log(notification)
        // this.areftnotificationsOn = true;
      } else {
        this.setNotificationEnabled(false);
        this.areftnotificationson = false;
      }
    }
    // return false;
  } 

  setNotificationEnabled(enabled: boolean): void {
    localStorage.setItem("notificationEnabled", enabled.toString());
    this.areftnotificationson = enabled;
  }

  goNextft() {
    this.router.navigateToRoute("pin_code_create");
  }

  goBack() {
    this.router.navigateBack();
  }
}
