import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { User } from "components/models/UserModel";
import { AuthService } from "aurelia-auth";

@autoinject
export class Fttheme {
    private me: User;
    private isDarkTheme: boolean = true;
    constructor(
        private router: Router,
        private authService: AuthService) {
    }


    // async getMe() {
    //     this.me = await this.authService.getMe();
    // }

    // isFirstTime() {
    //     this.me.isFirstTime = true
    // }

    // async goback() {
    //     await this.getMe();
    //     this.isFirstTime()
    //     console.log(this.me)
    //     if (this.me.isFirstTime) {
    //         console.log("continue flow")
    //         this.router.navigateToRoute('notification')
    //         return;
    //     }
    //     console.log("go back")
    //     this.router.navigateBack();
    // }
    goback() {
      this.router.navigateBack();
    }
}
